import {updateUserPreferences} from '../util/cookie-preferences/update-user-preferences';
import {EVENTS} from '../util/constants';
import Cookie from 'universal-cookie';
import {getUserPreferences} from '../util/cookie-preferences/get-user-preferences';
import {getLanguageAwareURL} from '../util/get-language-aware-url';

export const changeLanguage: (
    trackFn: (
        eventName: string,
        properties?: Record<string, unknown>,
        options?: Record<string, unknown>
    ) => Promise<void>,
    previousLang: string,
    newLang: string
) => Promise<{previousLang: string; currentLang: string}> = async (
    trackFn,
    previousLang,
    newLang
) => {
    const cookies = new Cookie(document.cookie);

    const userPrefs = {...getUserPreferences(cookies)};
    const userPreviousLang = userPrefs.lang;

    // The user previous lang might be equal to the new language in the following scenario
    // 1- The user has Spanish as its language.
    // 2- It goes to phonoma.com/en
    // 3- Hit the switch language button.
    if (userPreviousLang !== newLang) {
        userPrefs.lang = newLang;
        updateUserPreferences(cookies, userPrefs);
    }

    await trackFn(EVENTS.LANGUAGE_CHANGED, {
        previous: previousLang,
        current: newLang,
    });

    const redirectURL = getLanguageAwareURL(window.location.pathname, newLang);

    window.location.assign(redirectURL);
    return {previousLang: previousLang, currentLang: newLang};
};
